import img1 from "../images/common/asset-1.jpg";
import img2 from "../images/common/asset-2.jpg";
import img3 from "../images/common/asset-3.jpg";
import img4 from "../images/common/asset-4.jpg";
import img5 from "../images/common/asset-5.jpg";
import img6 from "../images/common/asset-6.jpg";
import img7 from "../images/common/asset-7.jpg";
import img9 from "../images/common/asset-8.jpg";
import img10 from "../images/common/asset-10.jpg";
import img11 from "../images/common/asset-11.jpg";
import img12 from "../images/common/asset-12.jpg";
import img13 from "../images/common/asset-13.jpg";

const dataProject = [
	{
		id: 1,
		title: "CYBOX #01",
		img: img1,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd01",
		link: "/gd01.glb",
	},
	{
		id: 2,
		title: "CYBOX #02",
		img: img2,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd01-1",
	},
	{
		id: 3,
		title: "CYBOX #03",
		img: img3,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd01-2",
	},
	{
		id: 4,
		title: "CYBOX #04",
		img: img4,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd01-3",
	},
	{
		id: 5,
		title: "CYBOX #05",
		img: img5,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd01-4",
	},
	{
		id: 6,
		title: "CYBOX #06",
		img: img6,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd01-5",
	},
	{
		id: 7,
		title: "CYBOX #07",
		img: img7,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd02",
	},
	{
		id: 8,
		title: "CYBOX #08",
		img: img2,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd02-1",
	},

	{
		id: 9,
		title: "CYBOX #09",
		img: img9,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd02-1",
	},
	{
		id: 10,
		title: "CYBOX #10",
		img: img10,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd03",
	},
	{
		id: 11,
		title: "CYBOX #11",
		img: img11,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd03",
	},
	{
		id: 12,
		title: "CYBOX #12",
		img: img12,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd05",
	},
	{
		id: 13,
		title: "CYBOX #13",
		img: img13,
		url: "https://4dscene.4dage.com/culturalrelics/SZGD/Model.html?m=gd06",
	},
];

export default dataProject;
