import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import AboutItem from "./about-item";

About.propTypes = {
	data: PropTypes.array,
};

function About(props) {
	const { data } = props;

	const [dataBlock] = useState({
		subtitle: "About us",
		title: "What is Golden Art?",
		desc: "PROJECT GOLDEN ART IS A JOINT VENTURE BETWEEN GOLDEN ART FINANCIAL GROUP INC. AND TAUCOIN ASSET MANAGEMENT LLC TO BRING DECENTRALIZED FINANCE TO THE ART INDUSTRY.",
	});

	return (
		<section className="tf-section tf-about">
			<div className="container">
				<div className="row">
					<div className="col-xl-5 col-md-12">
						<div
							className="content-about mobie-40"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							<div className="tf-title st2">
								<p className="h8 sub-title">{dataBlock.subtitle}</p>
								<h4 className="title">{dataBlock.title}</h4>
							</div>
							<p>{dataBlock.desc}</p>
							{/* <Button title="get Nfts" path="/" /> */}
						</div>
					</div>
					<div className="col-xl-7 col-md-12">
						<div
							className="wrap-about"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							{data.map((item) => (
								<AboutItem key={item.id} item={item} />
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
