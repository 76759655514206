export const ProjectTimeline = [
	{
		id: 1,
		time: "Sep 28-29 2022",
		list: [
			{
				text: "Target project announcement at Token2049 in Singapore",
			},
		],
		positon: "left",
		style: "normal",
	},
	{
		id: 2,
		time: "Nov 2022",
		list: [
			{
				text: "“A Thousand Miles of Rivers & Mountains” fractionalized NFT Sale or Metaverse Land Sale",
			},
		],
		positon: "right",
		style: "normal",
	},
	{
		id: 3,
		time: "Jan 2023",
		list: [
			{
				text: "Web3 NFT Marketplace Launch",
			},
		],
		positon: "left",
	},
	{
		id: 4,
		time: "Mar 2023",
		list: [
			{
				text: "Token Presale/Seedsale",
			},
		],
		positon: "right",
	},
	{
		id: 5,
		time: "Jun 2023",
		list: [
			{
				text: "DeFi Protocols Launch Blockchain Solutions Development Complete",
			},
		],
		positon: "left",
	},
	{
		id: 6,
		time: "Aug 2023",
		list: [
			{
				text: "Play-to-Earn Game Beta Release",
			},
		],
		positon: "right",
	},
];

export const ProjectStrategy = [
	{
		id: 1,
		time: "PHASE 1",
		list: [
			{
				text: "Issue NFTs of the fractionalized digital image of the Su embroidery of “A Thousand Miles of Rivers & Mountains” (one of the Top 10 Most Famous Chinese Paintings) or conduct metaverse land sale (via NFTs) of the project. The NFTs serve as entry tickets to participate in the project.",
			},
		],
		positon: "left",
		style: "normal",
	},
	{
		id: 2,
		time: "PHASE 2",
		list: [
			{
				text: "Develop a play-to-earn game where NFT holders from Phase 1 first enter the viewing gallery, and from there, decide which of the 5 dynasties to visit ( Tang Song Yuan Ming Qing, each dynasty a chapter of the game). Fractionalized NFTs of artworks & antiques from each dynasty will be placed in the chapter accordingly. When players collect all pieces of an object, the player(s) win the object. If the NFTs are backed by a physical asset, that player(s) win the underlying asset.",
			},
		],
		positon: "right",
		style: "normal",
	},
	{
		id: 3,
		time: "PHASE 3",
		list: [
			{
				text: "Launch web3 NFT Marketplace",
			},
		],
		positon: "left",
	},
	{
		id: 4,
		time: "PHASE 4",
		list: [
			{
				text: "Develop blockchain solution to support & facilitate dApps for this project",
			},
		],
		positon: "right",
	},
	{
		id: 5,
		time: "PHASE 5",
		list: [
			{
				text: "Develop DeFi protocols where institutional investors who participate in NFT purchase can direct how they wish to stake their NFTs.",
			},
		],
		positon: "left",
	},
];
