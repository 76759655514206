import { React, useEffect } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import "./assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";

function App() {
	useEffect(() => {
		AOS.init({
			duration: 2000,
		});
	}, []);

	return (
		<>
			<Header />

			<Routes>
				{routes.map((data, idx) => (
					<Route key={idx} path={data.path} element={data.component} exact />
				))}

				<Route path="*" element={<Page404 />} />
			</Routes>

			<Footer />
		</>
	);
}

// export default App;
// import { Canvas } from "@react-three/fiber";
// import { Environment, OrbitControls } from "@react-three/drei";
// import { Suspense } from "react";
// import React from "react";
// import Model from "./Gd01";

// function App() {
// 	return (
// 		<div className="App">
// 			<Canvas style={{ height: "100vh" }}>
// 				<Suspense fallback={null}>
// 					<Model />
// 					<OrbitControls />
// 					<Environment preset="sunset" />
// 				</Suspense>
// 			</Canvas>
// 		</div>
// 	);
// }

export default App;
