import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import cloud1 from "../../assets/images/cloud/cloud1.png";
import cloud2 from "../../assets/images/cloud/cloud2.png";
import cloud3 from "../../assets/images/cloud/cloud3.png";

Slider.propTypes = {
	data: PropTypes.array,
};

function Slider(props) {
	const { data } = props;
	const item = data[0];
	return (
		<section className="slider">
			<Grid sx={{ height: "750px", width: "100%", position: "relative" }}>
				<Grid
					sx={{
						position: "absolute",
						zIndex: 10,
						height: "102%",
						width: "100%",
						background: `rgb(0,0,0,0.2) linear-gradient(0deg, #0c1226 1%, rgb(12, 18, 38,0.6) 20%)`,
					}}
				></Grid>
				<Grid
					sx={{
						position: "absolute",
						zIndex: 9,
						height: "100%",
						width: "100%",
					}}
				>
					<div className="banner">
						<div className="clouds">
							<img src={cloud1} alt="cloud1" className="cloud1" />
							<img src={cloud2} alt="cloud2" className="cloud2" />
							<img src={cloud3} alt="cloud3" className="cloud3" />
							{/* <img src={cloud4} alt="cloud4" className="cloud4" /> */}
							{/* <img src={cloud5} alt="cloud5" className="cloud5" /> */}
						</div>
					</div>
				</Grid>
				<Grid
					item
					container
					sx={{
						position: "absolute",
						height: "100%",
						width: "100%",
						zIndex: 11,
					}}
					justifyContent="center"
					alignItems="center"
				>
					<Grid>
						<h1
							className="title"
							style={{
								fontFamily: "ARVO",
								color: "#febf68",
							}}
						>
							{item.title}
						</h1>
					</Grid>
				</Grid>

				{/* <Grid sx={{ height: "800px" }}>
					<img className="bg-slider" src={item.bgImg} alt="slider-1" />
				</Grid> */}
			</Grid>
		</section>
	);
}

export default Slider;
