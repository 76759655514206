import React from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./styles.scss";
import ProjectDisplay from "../project-display";

ProjectItem.propTypes = {
	item: PropTypes.object,
};

function ProjectItem(props) {
	const { item } = props;
	const [shown, setShown] = React.useState(false);
	return (
		<>
			{shown ? (
				<ProjectDisplay
					open={shown}
					onClose={() => setShown(false)}
					url={item.link}
				/>
			) : null}
			{item.link !== undefined && (
				<Grid
					container
					sx={{
						marginY: "20px",
						border: "1px solid #febf68",
						padding: "20px",
						background: "#0c1226",
						":hover": {
							border: "1px solid #febf68",
							boxShadow: `0px 0px 10px 3px #febf68`,
						},
					}}
					onClick={() => setShown(true)}
				>
					<Grid item xs={12} sx={{ background: "#b9b9bf" }} marginBottom="20px">
						<img src={item.img} alt="crybox" />
					</Grid>
					<Typography textAlign="center">{item.title}</Typography>
				</Grid>
			)}
			{item.link === undefined && (
				<Grid
					container
					flexDirection="column"
					justifyContent="center"
					sx={{
						marginY: "20px",
						border: "1px solid #febf68",
						padding: "20px",
						background: "#0c1226",
						":hover": {
							border: "1px solid #febf68",
							boxShadow: `0px 0px 10px 3px #febf68`,
						},
					}}
				>
					<Grid item xs={12} sx={{ background: "#b9b9bf" }} marginBottom="20px">
						<img src={item.img} alt="crybox" />
					</Grid>
					<Typography textAlign="center">{item.title}</Typography>
				</Grid>
			)}
		</>
	);
}

export default ProjectItem;
