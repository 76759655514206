import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import AboutItem from "./about-item";

About.propTypes = {
	data: PropTypes.array,
};

function About(props) {
	const { Innovation, globalOperations } = props;

	const [dataBlock] = useState({
		title: "What is TCAM?",
		subtitle1: "Innovation",
		subtitle2: "Global Operations",
	});

	return (
		<section
			className="tf-section tf-about style2"
			style={{
				marginTop: 0,
				paddingTop: 20,
			}}
		>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div
							className="content-about"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							<div className="tf-title">
								<p
									className="h8 sub-title"
									style={{ fontSize: "48px", fontFamily: "ARVO" }}
								>
									{dataBlock.title}
								</p>
								<h4
									className="title"
									style={{
										fontSize: "36px",
										marginTop: "50px",
										marginBottom: "50px",
									}}
								>
									{dataBlock.subtitle1}
								</h4>
							</div>
							<p className="pd20">{dataBlock.desc}</p>
						</div>
					</div>
					<div className="col-md-12">
						<div
							className="wrap-about"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							{Innovation.map((item) => (
								<AboutItem key={item.id} item={item} />
							))}
						</div>
					</div>
					<div className="col-md-12">
						<div
							className="content-about"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							<div className="tf-title">
								<h4
									className="title"
									style={{
										fontSize: "36px",
										marginTop: "50px",
										marginBottom: "50px",
									}}
								>
									{dataBlock.subtitle2}
								</h4>
							</div>
							<p className="pd20">{dataBlock.desc}</p>
						</div>
					</div>
					<div className="col-md-12">
						<div
							className="wrap-about"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							{globalOperations.map((item) => (
								<AboutItem key={item.id} item={item} />
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
