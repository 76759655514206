import bgImg1 from "../images/background/bg-1.jpg";
import bgImg2 from "../images/background/bg-2.jpg";
import img1 from "../images/common/itemslider.png";

const dataSlider = [
	{
		id: 1,
		title: "GOLDEN ART",
		bgImg: bgImg1,
		bgImg1: bgImg2,
		img: img1,
	},
];

export default dataSlider;
