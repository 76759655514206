import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import AboutItem from "../home-about/about-item";
import Button from "../../components/button";
import { Grid, Stack } from "@mui/material";

About.propTypes = {
	data: PropTypes.array,
};

function About(props) {
	const { data } = props;

	const [dataBlock] = useState({
		title: "Project Ecosystem",
		desc: "TCAM has designed the entire project incorporating web3 and decentralized finance.",
	});

	return (
		<section className="tf-section tf-about">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div
							className="content-about mobie-40 mb-60"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							<div className="tf-title">
								<p className="h8 sub-title">{dataBlock.subtitle}</p>
								<h4 className="title" style={{ color: "#febf68" }}>
									{dataBlock.title}
								</h4>
							</div>
							<p
								className="center"
								style={{
									color: "#fff",
									paddingTop: "30px",
									paddingBottom: "30px",
								}}
							>
								{dataBlock.desc}
							</p>
						</div>
					</div>
					<Stack
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: { xs: "column", lg: "row" },
							justifyContent: "space-around",
							alignItems: "center",
						}}
						spacing={{ xs: 2, lg: 0 }}
					>
						{data.map((item) => (
							<Grid item key={item.id}>
								<div
									className="wrap-about"
									data-aos="fade-up"
									data-aos-duration="800"
									style={{
										padding: 0,
									}}
								>
									<AboutItem item={item} />
								</div>
							</Grid>
						))}
					</Stack>

					<div className="col-md-12">
						<div
							className="btn-about center"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							<Button title="Get More" path="/ecosystem" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
