var util = {
  $: function (id) {
    return typeof id == "string" ? document.getElementById(id) : null;
  },
  cancel: function (event) {
    event.preventDefault();
    event.stopPropagation();
  },
  val: function (value) {
    return value && value > 0 ? value : 1;
  },
};

export function createPiece(img, row, column) {
  row = util.val(row);
  column = util.val(column);

  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");

  var wpiece = Math.floor(img.naturalWidth / column);
  var hpiece = Math.floor(img.naturalHeight / row);

  var src = "";
  var imgs = [];

  canvas.width = wpiece;
  canvas.height = hpiece;

  for (var i = 0; i < row; i++) {
    for (var j = 0; j < column; j++) {
      ctx.drawImage(
        img,
        j * wpiece,
        i * hpiece,
        wpiece,
        hpiece,
        0,
        0,
        wpiece,
        hpiece
      );

      src = canvas.toDataURL();
      imgs.push(src);
    }
  }
  return imgs;
}
