import React from "react";
import PageTitle from "../components/pagetitle";
import Ecosystem from "../features/ecosystem";
import { dataAboutEcosystem } from "../assets/fake-data/data-about";
function EcosystemPage(props) {
	return (
		<div className="page-ecosystem">
			<PageTitle title="Ecosystem" />
			<Ecosystem data={dataAboutEcosystem} />
		</div>
	);
}

export default EcosystemPage;
