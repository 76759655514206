import React from "react";

import "../scss/components/section.scss";
import "../scss/components/box.scss";
import Slider from "../components/home-background";
import dataSlider from "../assets/fake-data/data-background";
import About from "../features/home-about";
import Ecosystem from "../features/home-project-ecosystem";
import Project from "../features/project";
import RoadMap from "../features/roadmap/column";
import dataProject from "../assets/fake-data/dataProject";
import { dataAbout, dataAboutEcosystem } from "../assets/fake-data/data-about";
import { ProjectStrategy } from "../assets/fake-data/data-roadmap";

function HomeOne(props) {
	return (
		<div className="home-1">
			<Slider data={dataSlider} />

			<About data={dataAbout} />

			<Ecosystem data={dataAboutEcosystem} />

			<Project data={dataProject} />

			<RoadMap data={ProjectStrategy} />
		</div>
	);
}

export default HomeOne;
