import React from "react";
import { Modal, Box } from "@mui/material";
import PageTitle from "../components/pagetitle";
import { useForm, ValidationError } from "@formspree/react";
import { Link } from "react-router-dom";

function Contact(props) {
	const [state, handleSubmit] = useForm("xoqbebdw");

	return (
		<div>
			<PageTitle title="Contact Us" />
			{state.succeeded && (
				<Modal
					open={state.succeeded}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "15px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							background: "#141a31",
							borderRadius: "15px",
							padding: "40px",
						}}
					>
						<h3
							style={{
								marginBottom: "40px",
							}}
						>
							Thank you!
						</h3>
						<p
							style={{
								textAlign: "center",
							}}
						>
							You submission has been sent.
							<br />
							We are working on your request and will get in touch as soon as
							possible.
						</p>
						<button
							className="tf-button btn-effect"
							style={{
								marginTop: "20px",
							}}
						>
							<Link to={"/"}>
								<span className="boder-fade"></span>
								<span className="effect" style={{ color: "#febf68" }}>
									Back To Home
								</span>
							</Link>
						</button>
					</Box>
				</Modal>
			)}
			<section
				className="tf-section tf-contact"
				style={{
					marginTop: 0,
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-xl-5 col-md-12">
							<div
								className="content-about m-b50 mobie-40"
								data-aos="fade-right"
								data-aos-duration="800"
							>
								<div className="tf-title st2 m-b17">
									<h4 className="title" style={{ color: "#febf68" }}>
										Have a question
									</h4>
								</div>
								<p className="m-r-40" style={{ color: "#fff" }}>
									Fill up the Form and our team will get back to you.
								</p>
							</div>
							<form onSubmit={handleSubmit}>
								<fieldset>
									<input type="text" name="name" id="name" placeholder="Name" />
								</fieldset>
								<fieldset>
									<input
										type="email"
										name="mail"
										id="mail"
										placeholder="Email Address"
									/>
									<ValidationError
										prefix="Email"
										field="email"
										errors={state.errors}
									/>
								</fieldset>
								<fieldset>
									<input
										type="number"
										name="phone"
										id="phone"
										placeholder="Phone"
									/>
								</fieldset>
								<fieldset>
									<textarea
										placeholder="Type your Messege"
										rows="5"
										tabIndex="4"
										name="message"
										className="message"
										id="message"
									></textarea>
									<ValidationError
										prefix="Message"
										field="message"
										errors={state.errors}
									/>
								</fieldset>
								<button
									className="tf-button btn-effect"
									type="submit"
									disabled={state.submitting}
									style={{
										marginTop: "20px",
									}}
								>
									<span className="boder-fade"></span>
									<span className="effect" style={{ color: "#febf68" }}>
										Send Message
									</span>
								</button>
							</form>
						</div>
						<div className="col-xl-7  col-md-12">
							<div
								className="contact-details"
								data-aos="fade-left"
								data-aos-duration="800"
							>
								<div className="adress wrap-fx">
									<div className="location">
										<h6 style={{ color: "#febf68" }}>Location</h6>
										<ul>
											<li>
												100 Congress Ave, Ste. 2000, Austin, TX 78701, USA
											</li>
										</ul>
									</div>
									<div className="mail">
										<h6 style={{ color: "#febf68" }}>Contact Us</h6>
										<ul>
											<li>PHONE +1 5124693588 </li>
											<li>EMAIL info@tcam.co</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Contact;
