import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
// import { CubeTextureLoader } from "three";
import { Suspense } from "react";
import React from "react";
import Model from "./model";
// import img1 from "../assets/images/1.jpeg";

// function SkyBox() {
// 	const { scene } = useThree();
// 	const loader = new CubeTextureLoader();
// 	// The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
// 	const texture = loader.load([img1, img1, img1, img1, img1, img1]);
// 	// Set the scene background property to the resulting texture.
// 	scene.background = texture;
// 	return null;
// }

export default function ModelContainer(props) {
	const { url } = props;
	return (
		<Canvas style={{ width: "100%" }}>
			<Suspense fallback={null}>
				<Model url={url} position={[0, -1, 0]} />
				<OrbitControls maxDistance={7} minDistance={4} makeDefault />
				<perspectiveCamera />
				<ambientLight intensity={1} />
				{/* <SkyBox /> */}
			</Suspense>
		</Canvas>
	);
}
