import bgImg1 from "../images/ecosystem/bg-2.jpg";
import bgImg2 from "../images/ecosystem/bg-1.jpg";
import bgImg3 from "../images/ecosystem/bg-3.jpg";
import bgImg4 from "../images/ecosystem/bg-4.jpg";
import bgImg5 from "../images/ecosystem/bg-5.jpg";
import img1 from "../images/ecosystem/img-1.jpg";
import img2 from "../images/ecosystem/img-2.jpg";
import img3 from "../images/ecosystem/img-3.jpg";
import img4 from "../images/ecosystem/img-4.jpg";
import img5 from "../images/ecosystem/img-5.jpg";

export const dataAbout = [
	{
		id: 1,
		title: "Golden Art Financial Group Inc.",
		desc: `Golden Art Financial Group Inc. (“Golden Art”) is a Delaware incorporated company in the business of acquiring artwork & antiques globally. Golden Art's portfolio consists of more than 3,000+ pieces and the collections are primarily of Chinese origin. `,
		class: "active",
	},
	{
		id: 2,
		title: "Taucoin Asset Management LLC",
		desc: `Taucoin Asset Management LLC (“TCAM”) is a global fintech & asset management company headquartered in the US. TCAM employs blockchain technology in all aspect of its business segments. `,
	},
];

export const dataAboutEcosystem = [
	{
		id: 1,
		title: "NFT ISSUANCE",
		desc: `Platform`,
		image: img1,
		bgImage: bgImg1,
		detail: `Project Golden Art is the first platform to issue NFTs backed by
					fractional ownership of artwork & antiques. Anyone who collects all
					the NFTs of a single item may claim ownership of the underlying
					physical asset. Golden Art will deliver the physical asset to the NFTs
					owner’s final designated location of choice. For Chinese artwork and
					antiques classified as culture relics by the Chinese government which
					can be sold to private collectors by not exported outside of China,
					the NFTs will be backed by the copyright (the digital image) of the
					underlying artwork or antique.`,
		class: "active",
	},
	{
		id: 2,
		title: "BLOCKCHAIN",
		desc: "Solutions",
		image: img2,
		bgImage: bgImg2,
		detail:
			"TCAM IS DEVELOPING A BLOCKCHAIN SOLUTION TO SUPPORT & FACILITATE ALL DAPPS FOR THIS PROJECT, INCLUDING NFT AND TOKEN ISSUANCE, A DECENTRALIZED WALLET, AND DEFI PROTOCOLS.",
	},
	{
		id: 3,
		title: "NFT",
		desc: "Marketplace",
		image: img3,
		bgImage: bgImg3,
		detail: `THIS WEB3 NFT MARKETPLACE ALLOWS USERS TO BUY AND TRADE NFTS BACKED BY EITHER FRACTIONAL OWNERSHIP OF PHYSICAL ARTWORK OR ANTIQUES, OR COPYRIGHT (THE DIGITAL IMAGE) OF THE UNDERLYING ASSETS IN ORDER TO COLLECT ALL THE NFT ISSUED OF A SINGLE ITEM. USERS CAN EITHER STORE THESE NFTS USING THEIR OWN DECENTRALIZED WALLET SUCH AS METAMASK OR IN THE WALLET DEVELOPED BY THE PROJECT.`,
	},
	{
		id: 4,
		title: "PLAY-TO-EARN",
		desc: "Metaverse",
		image: img4,
		bgImage: bgImg4,
		detail: `TCAM IS DEVELOPING A PLAY-TO-EARN GAME INCORPORATING THESE NFTS WHERE USERS COMPLETE CERTAIN TASKS IN ORDER TO WIN THE UNDERLYING PHYSICAL CHINESE ARTWORK AND ANTIQUES AS REWARDS.`,
	},
	{
		id: 5,
		title: "DE-FI",
		desc: "Protocols",
		image: img5,
		bgImage: bgImg5,
		detail: `TCAM IS DEVELOPING DEFI PROTOCOLS TO FACILITATE THE ASSET MANAGEMENT ASPECT OF THE PROJECT, SUCH AS HOW INSTITUTIONAL NFT BUYERS WISH TO ALLOCATE THEIR INVESTMENT DECISIONS WHEN THEY STAKE THEIR NFTS.`,
	},
];

export const Innovation = [
	{
		id: 1,
		desc: `By offering fractional NFTs backed by either physical asset or the copyright of Chinese artwork and antiques, TCAM has created an innovative approach to invest in this asset class which has been historically illiquid with a high barrier to access.`,
	},
	{
		id: 2,
		desc: `TCAM has obtained a license relating to the following patents to develop the project:`,
		point: [
			{ id: "1", desc: "U.S. Patent No. 7,716,087" },
			{ id: "2", desc: "U.S. Patent No. 7,647,464" },
			{ id: "3", desc: "U.S. Patent No. 8,341,027" },
			{ id: "4", desc: "U.S. Patent No. 8,473,359" },
			{ id: "5", desc: "U.S. Patent No. 9,837,818" },
			{
				id: "6",
				desc: `Provisional Patent Application No. 63/314,916 entitled "Decentralized Products and Rewards Delivery Platform"`,
			},
		],
	},
];
export const globalOperations = [
	{
		id: 1,
		desc: `Taucoin Technology Company DMCC ("TCTC Dubai"), TCAM's  wholly-owned subsidiary, was recently granted a NFT E-marketplace license by DMCC (Dubai Multi Commodities Center).`,
	},
	{
		id: 2,
		desc: `Taucoin Technology Pty Ltd. ("TCTC Australia"), TCAM's wholly-owned subsidiary and its world-class R&D Center, conducts all of TCAM's technical & business development leveraging blockchain technology. Current team consists of 15 members.`,
	},
];
export default dataAbout;
