import { useState, useEffect } from "react";
import { Dialog, DialogTitle, CircularProgress } from "@mui/material";
import PageTitle from "../components/pagetitle";
import demoImage from "../assets/images/demo.jpg";
import { createPiece } from "../utils/split";

function SimpleDialog(props) {
  const { onClose, selectedId, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} >
      <DialogTitle>Token #{selectedId+1}</DialogTitle>
      <img style={{ width: 300, maxWidth: 300}} alt={`Token #${selectedId}`} src={selectedValue} />
    </Dialog>
  );
}

function Demo() {
  const [splitImagePieces, setSplitImagePieces] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(true);


  const handleClickOpen = (id, value) => {
    setSelectedId(id)
    setSelectedValue(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId('')
    setSelectedValue('');
  };

  const splitImage = async () => {
    try {
      setLoading(true);
      const newImg = new Image();
      newImg.src = await new Promise((resolve) => resolve(demoImage));
      newImg.onload = () => {
        const imgs = createPiece(newImg, 10, 20);
        setSplitImagePieces(imgs);
      };
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    splitImage();
  }, []);

  return (
    <div>
      <PageTitle title="Demo" />
      <section
        className="tf-section tf-contact"
        style={{
          marginTop: 0,
        }}
      >
        <div className="container" style={{ textAlign: 'center', overflowX: 'scroll' }}>
          {loading ? <CircularProgress style={{ color: '#febf68' }} /> : 
          <div class="grid-container" style={{ minWidth: 1000 }}>
            {splitImagePieces.map((i, idx) => (
              <>
                <span className="grow-img" onClick={() => handleClickOpen(idx, i)}>
                  <img alt={idx} key={idx} src={i} style={{ padding: 0.5, width: 50, maxWidth: 50 }} />
                </span>
                {(idx + 1) % 20 === 0 && <br />}
              </>
            ))}
          </div>
          }
        </div>
      </section>
      <SimpleDialog
        selectedValue={selectedValue}
        selectedId={selectedId}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

export default Demo;
