import Home from "./Home";
import ComingSoon from "./ComingSoon";
import Page404 from "./404";
import Ecosystem from "./EcosystemPage";
import ProjectTimeline from "./ProjectTimeline";
import About from "./About";
import Contact from "./Contact";
import Demo from "./Demo";

const routes = [
	{ path: "/", component: <Home /> },
	{ path: "/ecosystem", component: <Ecosystem /> },
	{ path: "/coming-soon", component: <ComingSoon /> },
	{ path: "/404", component: <Page404 /> },
	{ path: "/project-roadmap", component: <ProjectTimeline /> },
	{ path: "/about", component: <About /> },
	{ path: "/contact", component: <Contact /> },
	{ path: "/demo", component: <Demo /> },
];

export default routes;
