import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import "../styles.scss";
import img5 from "../../../assets/images/common/roadmap.jpg";

import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

RoadMap.propTypes = {
	data: PropTypes.array,
};

function RoadMap(props) {
	const { data } = props;

	return (
		<section
			className="tf-section tf-roadmap style2 style3"
			style={{
				marginTop: 50,
				padding: 0,
			}}
		>
			<Grid
				sx={{
					height: "100%",
					width: "100%",
					backgroundImage: `linear-gradient(0deg, #0c1226 10%, rgba(0, 0, 0, 0) 60%,  #0c1226 100%), url(${img5}) !important`,
				}}
			>
				<div className="container-fluid">
					<div className="row">
						<div
							className="col-md-12"
							style={{
								marginTop: "150px",
								marginBottom: "100px",
							}}
						>
							<div
								className="roadmap style2 style3"
								data-aos="fade-up"
								data-aos-duration="800"
							>
								<Swiper
									modules={[Navigation, Scrollbar, A11y]}
									spaceBetween={60}
									navigation
									scrollbar={{ draggable: true }}
									breakpoints={{
										0: {
											slidesPerView: 1,
										},
										767: {
											slidesPerView: 2,
										},
										991: {
											slidesPerView: 3,
										},
									}}
								>
									{data.map((item) => (
										<SwiperSlide key={item.id}>
											<div className="box">
												<div className="rm-box">
													<div className={`corner-box-roadmap ${item.style}`}>
														<h5>{item.time}</h5>
														<ul>
															{item.list.map((li, idx) => (
																<li key={idx}>{li.text}</li>
															))}
														</ul>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
					</div>
				</div>
			</Grid>
		</section>
	);
}

export default RoadMap;
