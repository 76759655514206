import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles.scss";
import RoadMapItem from "../roadmap-item";

RoadMap.propTypes = {
	data: PropTypes.array,
};

function RoadMap(props) {
	const { data } = props;

	const [dataBlock] = useState({
		subtitle: "ROADMAP",
		title: "Go-To-Market Strategy",
	});

	return (
		<section className="tf-section tf-roadmap">
			<div className="overlay"></div>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div
							className="tf-title mb-30"
							data-aos="fade-up"
							data-aos-duration="800"
						>
							<h4 className="title" style={{ color: "#febf68" }}>
								{dataBlock.title}
							</h4>
							<p className="h8 sub-title" style={{ color: "#fff" }}>
								{dataBlock.subtitle}
							</p>
						</div>
					</div>
					<div className="col-md-12">
						<div className="roadmap-column scrol">
							{data.map((item) => (
								<RoadMapItem key={item.id} item={item} />
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default RoadMap;
