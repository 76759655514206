/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
	const { url } = props;
	const { nodes, materials } = useGLTF(url);
	return (
		<group {...props} dispose={null} scale={0.2}>
			<mesh
				geometry={nodes.gd01_dizuo_low.geometry}
				material={materials.blinn4SG}
				rotation={[Math.PI / 2, 0, 0]}
			/>
		</group>
	);
}

useGLTF.preload("/gd01.glb");
