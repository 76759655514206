import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
AboutItem.propTypes = {
	item: PropTypes.object,
};

function AboutItem(props) {
	const { item } = props;

	return (
		<div key={item.id} className={`box-text corner-box ${item.class}`}>
			{item.image !== undefined && (
				<>
					<Grid marginY={2} width="200px">
						<img src={item.image} alt={item.id}></img>
					</Grid>
					<div
						className="h7"
						style={{
							textAlign: "center",
						}}
					>
						{item.title}
					</div>
					<p
						style={{
							textAlign: "center",
						}}
					>
						{item.desc}
					</p>
					{item.point !== undefined && (
						<>
							{item.point.map((x) => (
								<li key={x.id}> * {x.desc}</li>
							))}
						</>
					)}
				</>
			)}
			{item.image === undefined && (
				<>
					<div className="h7">{item.title}</div>
					<p>{item.desc}</p>
					{item.point !== undefined && (
						<>
							{item.point.map((x) => (
								<li key={x.id}> * {x.desc}</li>
							))}
						</>
					)}
				</>
			)}
		</div>
	);
}

export default AboutItem;
