import React from "react";
import { ProjectTimeline } from "../assets/fake-data/data-roadmap";
import PageTitle from "../components/pagetitle";
import RoadMap from "../features/roadmap/row";

function RoadMapTwo(props) {
	return (
		<div className="page-roadmap-v2">
			<PageTitle title="Project Timeline" />
			<RoadMap data={ProjectTimeline} />
		</div>
	);
}

export default RoadMapTwo;
