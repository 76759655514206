const menus = [
	{
		id: 1,
		name: "Home",
		links: "/",
	},
	{
		id: 2,
		name: "Ecosystem",
		links: "/ecosystem",
	},
	{
		id: 3,
		name: "Project Roadmap",
		links: "/project-roadmap",
	},
	{
		id: 4,
		name: "About",
		links: "/about",
	},
	{
		id: 6,
		name: "Contact",
		links: "/contact",
	},
];

export default menus;
