import { Modal, Box } from "@mui/material";
import Model from "../../../model/modelContainer";

function ProjectDisplay(props) {
	const { open, onClose, url } = props;
	console.log(open);
	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					width: "800px",
					height: "500px",
					background: "#b9b9bf",
					borderRadius: "20px",
					":focus-visible": {
						outline: "none",
					},
				}}
			>
				<Model url={url} />
			</Box>
		</Modal>
	);
}
export default ProjectDisplay;
