import React from "react";
import { Grid, Typography } from "@mui/material";

function Ecosystem(props) {
	const { data } = props;
	return (
		<>
			{data.map((item) => (
				<Grid
					key={item.id}
					sx={{
						position: "relative",
						marginBottom: item.id === data.length ? 0 : "200px",
						height: "800px",
						backgroundImage:
							item.id % 2 !== 0
								? `linear-gradient(90deg, #0c1226 30%, rgba(0, 0, 0, 0) 60%), url(${item.bgImage})`
								: `linear-gradient(-90deg, #0c1226 20%, rgba(0, 0, 0, 0) 50%), url(${item.bgImage})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						display: "flex",
						justifyContent: item.id % 2 !== 0 ? "flex-start" : "flex-end",
						alignItems: "center",
						paddingLeft: { xs: "10px", md: item.id % 2 !== 0 ? 20 : 0 },
						paddingRight: { xs: "10px", md: item.id % 2 === 0 ? 20 : 0 },
					}}
				>
					<div
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							backgroundImage: `linear-gradient(0deg, #0c1226 1%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%,#0c1226 99%)`,
						}}
					></div>

					<div
						className="col-sm-5 col-xs-12"
						style={{
							paddingX: "5px",
						}}
					>
						<div className="tf-title st2">
							<h4 className="title">{item.title}</h4>
							<p className="h8 sub-title">{item.desc}</p>
						</div>
						<Typography
							sx={{
								fontFamily: `IBM Plex Mono, monospace`,
								fontSize: "17px",
								lineHeight: "28px",
								fontWeight: 400,
								color: { xs: "white", md: "#b9b9bf" },
							}}
						>
							{item.detail}
						</Typography>
					</div>
				</Grid>
			))}
		</>
	);
}

export default Ecosystem;
