import React from "react";
import PageTitle from "../components/pagetitle";
import About from "../features/about-page";
import { Innovation, globalOperations } from "../assets/fake-data/data-about";

function AboutPage(props) {
	return (
		<div>
			<PageTitle title="About Us" />
			<section
				className="tf-section team-detail "
				style={{
					marginTop: 0,
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-xl-5 col-lg-5 col-md-5">
							<div
								className="image-details"
								data-aos="fade-right"
								data-aos-duration="800"
							>
								<img
									src={require("../assets/images/common/about.jpg")}
									alt=""
								/>
							</div>
						</div>
						<div className="col-xl-7 col-lg-7 col-md-7">
							<div
								className="info-detail"
								data-aos="fade-left"
								data-aos-duration="800"
							>
								<p className="sub" style={{ fontSize: "36px" }}>
									Golden Art has engaged TCAM to:
								</p>
								<div className="box">
									<div className="h8">
										Issue the first series of fractionalized NFTs that are
										backed by either the physical artwork & antiques or the
										copyright of the assets it owns, the issuance of the NFTs
										are based on relevant Chinese culture relic classification
										and export laws, and subject to legal and regulatory review
									</div>
								</div>
								<div className="box contact">
									<div className="h8">
										Manage a portion of the NFT sales proceeds; the returns on
										those investments (less management fees) will be distributed
										back to NFT holders.
									</div>
								</div>
								<div className="box contact">
									<div className="h8">
										The total estimated appraised value of the artwork and
										antiques earmarked for the project is US$5 billion. Please
										see Appendix for a list of the collections
									</div>
								</div>
								<div className="box contact">
									<div className="h8">
										In addition to the ownership of the underlying artwork/
										antiques/the copyright of the digital image of the physical
										assets, NFT owners are entitled a share of Golden Art’s
										investment returns generated from the proceeds NFT sale
										managed by TCAM.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<About Innovation={Innovation} globalOperations={globalOperations} />
		</div>
	);
}

export default AboutPage;
